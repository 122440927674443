import { v4 as uuidv4 } from 'uuid';
import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';
import { AuthorizationError } from '../../util/error';

const defaultState = {
    stayLoggedIn: false,
    reloadLoggedIn: false,
    token: "",
    account: {},

    // Used for identifying users device,
    // should be generated once per device
    deviceToken: null,
};

const mutations = {
    login(state, token) {
        state.token = token;
    },
    setAccount(state, account) {
        state.account = account;
    },
    setStayLoggedIn(state, stay) {
        state.stayLoggedIn = stay;
    },
    setReloadLoggedIn(state, reload) {
        state.reloadLoggedIn = reload;
    },
    setDeviceToken(state, deviceToken) {
        state.deviceToken = deviceToken;
    },
    logout(state) {
        state.token = "";
        state.account = null;
        state.stayLoggedIn = false;
    },
};

const actions = {
    async loginUser({ commit, dispatch, state }, { accountName, password }) {
        commit('appointments/setAppointments', [], { root: true });
        const regexNoWhitespacesAtStartEnd = /^\s+|\s+$/gm;
        password = password.replace(regexNoWhitespacesAtStartEnd, '');

        if (!state.deviceToken) {
            commit('setDeviceToken', uuidv4());
        }

        const res = await backend.loginAccount({
            accountName,
            password,
            deviceToken: state.deviceToken,
        });

        if (res.status === 401) {
            throw new AuthorizationError('Login credentials seem to be wrong.');
        }
        await checkResponseStatus(200, res);

        const { token } = await res.json();
        
        commit('login', token);
        const account = await dispatch('getAccount');

        return account;
    },
    // other route overrides appointments resulting in an empty timetable
    async confirmAccountPassword({ commit, dispatch, state }, { accountName, password }) {
        const trimWhitespaces = /^\s+|\s+$/gm;
        password = password.replace(trimWhitespaces, '');

        if (!state.deviceToken) {
            commit('setDeviceToken', uuidv4());
        }

        const res = await backend.confirmAccountPassword({
            accountName,
            password,
            deviceToken: state.deviceToken,
        });

        try {
            await checkResponseStatus(200, res);
            return res;
        } catch (err) {
            return null;
        }
    },
    // Request backend with current token to see if it is still valid
    // and return current user
    async revalidateUserLogin({ commit }) {
        try {
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            commit('setAccount', account);
            return account;
        }
        catch (err) {
            commit('setAccount', null);
            return null
        }
    },
    async getAccount({ commit }) {
        const resCurrentAccount = await backend.getCurrentAccount();
        try {
            const account = await resCurrentAccount.json();
            commit('setAccount', account);
            return account;
        } catch (error) {
            commit('setAccount', null);

        }
    },

    async acceptTermsOfService({ dispatch }) {
        try {
            const res = await backend.acceptTermsOfService();
            await checkResponseStatus(200, res);
            dispatch('getAccount');
        }
        catch (err) {
            return err.response.status;
        }
    },

    async changeStayLoggedIn({ commit }, stay) {
        commit('setStayLoggedIn', stay);
    },
    autoLogoutUser({ commit, state }) {
        if (!state.stayLoggedIn && !state.reloadLoggedIn) {
            commit('appointments/setAppointments', [], { root: true });
            commit('logout');
        }
        commit('setReloadLoggedIn', false);
    },
    async logoutUser({ commit }) {
        await backend.unsubscribe();
        commit('appointments/setAppointments', [], { root: true });
        commit('logout');
    },
    async reloadDontLogout({ commit }, reload) {
        commit('setReloadLoggedIn', reload);
    },
    async isLoginIdAvailable({ commit, dispatch }, loginId) {
        try {
            const res = await backend.postNameAvailable(loginId);
            await checkResponseStatus(200, res);
            const available = await res.json();
            return available;
        }
        catch (err) {
            return err.response.status;
        }
    },

    async getCurrentAccount({ commit, dispatch }) {
        try {
            const res = await backend.getCurrentAccount();
            await checkResponseStatus(200, res);
            const account = await res.json();
            return account;
        }
        catch (err) {
            return err.response.status;
        }
    },

    async getSecurityQuestions({ commit, dispatch }) {
        try {
            const res = await backend.getSecurityQuestions();
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response.status;
        }
    },

};

const getters = {
    accountId: state => state.account ? state.account._id : undefined,
    accountRole: state => state.account ? state.account.role : undefined,
}

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
