import * as backend from '../../api/backend';
import { checkResponseStatus } from '../../util/check';

const defaultState = {
};

const mutations = {
};

const actions = {

  async getTeacherFolder ({ commit, dispatch }, params) {

    try {
        const folderType = params.folderType;
        const folderId = params.folderId;
        const res = await backend.getVFSFolderUploads(folderType, folderId);
        await checkResponseStatus(200, res);
        const teacherFiles = await res.json();
        return teacherFiles;
    }
    catch(err) {
        return err.response.status;
    }
  },

  async getVFSFolderUpload({ commit, dispatch }, fileId) {
    try {
        
        const res = await backend.getVFSFolderUpload(fileId);
        return res;
    }
    catch(err) {
        return err;
    }
  },

  async deleteTeacherFile ({ commit, dispatch }, data) {

    try {
      const res = await backend.deleteVFSFolderUpload(data);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

  async createVFSFolder ({ commit, dispatch }, params) {

    try {
      const folderId = params.currentFolderId;
      delete params.currentFolderId;
      const res = await backend.createVFSFolder(folderId, params);
      await checkResponseStatus(200, res);
      const newFolder = await res.json();
      return newFolder;
    }
    catch(err) {
      return err.response.status;
    }
  },

  async updateVFSFolder ({ commit, dispatch }, data) {

    try {
        const res = await backend.updateVFSFolder(data._id, data);
        await checkResponseStatus(200, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

  async updateVFSFolderUpload ({ commit, dispatch }, data) {

    try {
        const res = await backend.updateVFSFolderUpload(data.fileId, data);
      await checkResponseStatus(200, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteVFSFolder ({ commit, dispatch }, folderId) {

    try {
      const res = await backend.deleteVFSFolder(folderId);
      await checkResponseStatus(204, res);
    }
    catch(err) {
      return err.response.status;
    }
  },

};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
};
