<template>
    <div>
        <v-row class="mx-0 mt-3 mb-4">
            <div style="height: auto; width: 90%; margin: auto; border-radius: 15px; box-shadow: 1px 5px 5px silver;">
                <div
                    style="background-color: var(--v-gelb-base); border-radius: 15px 15px 0 0; font-size: larger; padding-top: 5px; padding-bottom: 20px; text-align: center; color: white;"
                >
                    <div style="display: flex; width: 50%">
                        <img
                            :src="suchIcon"
                            style="height: 24px; margin-left: 24px; margin-top: 20px;
                        filter: brightness(0) saturate(100%) invert(100%) sepia(5%) saturate(19%) hue-rotate(65deg) brightness(104%) contrast(105%);"
                            alt="Lupen-Icon"
                        >

                        <v-text-field
                            v-model="search"
                            label="Nach Schüler suchen"
                            single-line
                            dark
                            hide-details
                            style="width: 50%"
                            class="pl-6"
                        />
                    </div>
                </div>
                <div
                    style="display: flex; font-size: larger; font-family: Roboto; margin-top: 24px; margin-left: 26px"
                    class=""
                >
                    <img
                        :src="pupilIcon"
                        style="height: 24px; margin-right: 10px"
                    >
                    Schüler
                </div>
                <v-data-table
                    :headers="headers"
                    :items="list"
                    :search="search"
                    sort-by="lastName"
                    disable-pagination
                    hide-default-footer
                    fixed-header
                    :height="height"
                    class="ma-3"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{ row.item.lastName }}</td>
                            <td> {{ row.item.name }}</td>
                            <td>{{ row.item.gender }}</td>
                            <td>
                                <v-btn
                                    class="editPupilButton mx-2"
                                    color="#EAEAEA"
                                    small
                                    elevation="1"
                                    light
                                    @click="editUser(row.item);"
                                >
                                    <img :src="bearbeitenIcon">Schülerprofil bearbeiten
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-overlay
                    absolute
                    :value="showLoadingOverlay"
                    style="width: 100% !important;"
                >
                    <v-progress-circular
                        v-if="showLoadingOverlay"
                        indeterminate
                        size="100"
                    />
                </v-overlay>
            </div>
        </v-row>

        <!-- Schüler anlegen / bearbeiten -->
        <v-row
            id="edit"
            class="mx-0 my-3"
        >
            <div class=" editContainer">
                <v-row
                    class="mx-0"
                    style="background-color: var(--v-gelb-base); border-radius: 15px 15px 0 0"
                >
                    <v-col
                        style="color: white; font-size: larger;"
                        class="ma-1 ml-5"
                    >
                        <v-avatar
                            style="width: 24px;"
                            color="rgba(255,255,255,0.8)"
                        >
                            <img
                                :src="pupilIcon"
                                class=""
                            >
                        </v-avatar>
                        Schüler {{ oldProfile ? 'bearbeiten' : 'anlegen' }}
                    </v-col>
                    <v-col
                        class="mr-6"
                        style="display: flex; justify-content: flex-end;"
                    >
                        <v-tooltip
                            top
                            :open-on-click="false"
                            :open-on-hover="true"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    class="my-auto"
                                    v-on="on"
                                >
                                    <v-btn
                                        v-if="!oldProfile && debugMode"
                                        color="#FFFFFF40"
                                        x-small
                                        class="mainFunctionButtons ml-3 my-auto"
                                        v-bind="attrs"
                                        @click="clickExelUploadButton"
                                        v-on="on"
                                    >
                                        <img :src="hochladen">
                                    </v-btn>
                                </span>
                            </template>
                            <span>Hier können Sie eine Excel-Datei mit Schüler-Daten hochladen.</span>
                        </v-tooltip>
                        <v-btn
                            color="#FFFFFF40"
                            x-small
                            class="mainFunctionButtons ml-3 my-auto"
                            :disabled="!oldProfile"
                            @click="setDeleteDialog('pupil')"
                        >
                            <img :src="loeschIcon">
                        </v-btn>
                        <v-tooltip
                            top
                            :open-on-click="false"
                            :open-on-hover="true"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                    v-bind="attrs"
                                    class="my-auto"
                                    v-on="on"
                                >
                                    <v-btn
                                        color="#FFFFFF40"
                                        x-small
                                        class="mainFunctionButtons ml-3"
                                        disabled
                                        @click="pdf"
                                    >
                                        <!-- :disabled="!(togglePDFDownload)" -->
                                        <img :src="pdfDocIcon">
                                    </v-btn>
                                </span>
                            </template>
                            <span>Aktuell noch nicht verfügbar.</span>
                        </v-tooltip>
                        <v-tooltip
                            top
                            nudge-right="100"
                            :open-on-click="true"
                            :open-on-hover="false"
                            content-class="infoTextToolTip"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="#FFFFFF40"
                                    x-small
                                    class="mainFunctionButtons ml-3 my-auto"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <img :src="infoIcon">
                                </v-btn>
                            </template>
                            <span>Hier können Sie Schüler und Erziehungsberechtigte anlegen oder bearbeiten.</span>
                        </v-tooltip>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#BDBDBD50"
                            dark
                            elevation="0"
                            @click="stopEdit"
                        >
                            <span>Abbrechen</span>
                        </v-btn>
                        <v-btn
                            class="mainControlButtons ml-3 my-auto pa-5"
                            color="#69CD70"
                            dark
                            elevation="4"
                            @click="openDialog"
                        >
                            <span>Speichern</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mx-2 mb-2">
                    <!--child-->
                    <v-row>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Persönliche Daten
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Vorname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="name"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.name }"
                                        @change="() => { suggestLoginId(oldProfile); }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.name && name.length === 0"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-else-if="fields.name"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Nachname:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="lastName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.lastName }"
                                        @change="() => { suggestLoginId(oldProfile); generateParentLoginId() }"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.lastName && lastName.length === 0"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row
                                v-else-if="fields.lastName"
                                dense
                            >
                                <v-col
                                    class="pl-2; pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Name darf keine Zahlen enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select
                                        v-model="gender"
                                        class="input"
                                        :class="{ errorInput: fields.gender }"
                                        @change="validateInput"
                                    >
                                        <option
                                            disabled
                                            selected
                                            value
                                        >
                                            Auswählen
                                        </option>
                                        <option value="m">
                                            Männlich
                                        </option>
                                        <option value="w">
                                            Weiblich
                                        </option>
                                        <option value="d">
                                            Divers
                                        </option>
                                        <option value="">
                                            Keine Angabe
                                        </option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.gender"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Notiz:
                                </v-col>
                                <v-col cols="7">
                                    <textarea
                                        v-model="note"
                                        class="input"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                            class="mx-5"
                            style="margin-right: auto"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Schulisches
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Gruppen:
                                </v-col>
                                <v-col cols="7">
                                    <v-row
                                        v-for="(item, index) in groups"
                                        :key="index"
                                    >
                                        <div
                                            class="px-3 py-1 mb-1"
                                            style="min-width: 200px; background-color: var(--v-hellgrau-base); border-radius: 8px"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="8"
                                    style="height: 48px"
                                >
                                    <div
                                        class="d-flex"
                                        style="display: inline-flex; white-space: nowrap;"
                                    >
                                        Einfache Ansicht:
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                    class="pl-2"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    fa-question-circle
                                                </v-icon>
                                            </template>
                                            <v-row class="ml-0">
                                                Einfache Ansicht:
                                            </v-row>
                                            <v-row>
                                                <img
                                                    style="height: 100px"
                                                    :src="easy"
                                                >
                                            </v-row>
                                            <v-row class="ml-0">
                                                Normale Ansicht:
                                            </v-row>
                                            <v-row>
                                                <img
                                                    style="height: 100px"
                                                    :src="hard"
                                                >
                                            </v-row>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 48px"
                                >
                                    <v-checkbox
                                        v-model="babyView"
                                        :ripple="false"
                                        class="mt-0 pt-0 babyViewCheckbox"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    style="height: 36px"
                                    class="pb-0"
                                >
                                    <div
                                        class="d-flex"
                                        style="display: inline-flex; text-overflow: ellipsis; white-space: nowrap;"
                                    >
                                        Barrierefreier Modus
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Sehen
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="sehen"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="2"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="6"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <span
                                        style="margin-left: -8px"
                                        :style="sehen ? '' : 'color: rgba(0, 0, 0, 0.4) !important'"
                                    >
                                        ⮡</span><span
                                            style="margin-left: 10px; padding-right: 5px"
                                            :style="sehen ? '' : 'color: rgba(0, 0, 0, 0.4) !important'"
                                        >Screenreader</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                fa-question-circle
                                            </v-icon>
                                        </template>
                                        <span>Es werden Funktionen deaktiviert, welche für Screenreader Nutzer nutzlos sind (Vorlesefunktion, Stundenplan im Vollbild)</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="screenreader"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                        :disabled="!sehen"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Hören
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="hoeren"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="1"
                                    style="height: 24px"
                                    class="py-0"
                                />
                                <v-col
                                    cols="7"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    Motorik
                                </v-col>
                                <v-col
                                    cols="3"
                                    style="height: 24px"
                                    class="py-0"
                                >
                                    <v-checkbox
                                        v-model="motorik"
                                        :ripple="false"
                                        class="mt-0 pt-0"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            class="mx-5"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="makePassword"
                                    >
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Login-ID*:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editLoginId = true;"
                                >
                                    <input
                                        v-model="userName"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.userName }"
                                        :disabled="!editLoginId || oldProfile"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.userName"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben enthalten.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editPassword = true"
                                >
                                    <input
                                        v-model="password"
                                        :type="editPassword ? 'text' : (oldProfile && !newPassword) ? 'password' : 'text'"
                                        :disabled="!editPassword"
                                        class="input"
                                        :class="{ errorInput: fields.password }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.password"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <p
                                        class="mb-2"
                                        style="color: #808080;"
                                    >
                                        Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="7">
                                    <v-btn
                                        v-if="false"
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="makePassword"
                                    >
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        v-if="false"
                                        class=""
                                        style="max-height: 25px;max-width: 100%; width: 100%; border-radius: 8px; background-color: rgba(230,34,30,0.41)"
                                        color=""
                                        small
                                        light
                                        @click="deleteDialog = true"
                                    >
                                        Löschen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-spacer class="mt-1" />
                        </v-col>
                    </v-row>

                    <hr style="height: 1px; color: var(--v-hellgrau-base); background-color: var(--v-hellgrau-base); border: 0 none;">

                    <!--choose from existing parent-->
                    <v-row v-if="!pupilHasParent">
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Vorhandene Erziehungsberechtigte
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        v-model="selectedParent"
                                        :items="parents"
                                        item-text="account.accountName"
                                        :disabled="createNewParent"
                                        hide-details="true"
                                        class="ma-0 pa-0"
                                        :class="{ errorInput: fields.parent }"
                                        no-data-text="Keine Eltern vorhanden"
                                    />
                                </v-col>

                                <v-col cols="6">
                                    <v-row>
                                        <v-col
                                            cols="11"
                                            class="pa-0 pl-4 d-flex align-start"
                                        >
                                            Neuen Erziehungsberechtigten anlegen:
                                        </v-col>
                                        <v-col
                                            cols="1"
                                            class="pa-0 d-flex justify-center align-start"
                                        >
                                            <v-checkbox
                                                v-model="createNewParent"
                                                :class="{ errorInput: fields.parent }"
                                                class="ma-0"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!--new parent-->
                    <v-row v-if="pupilHasParent || createNewParent">
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Erziehungsberechtigter
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Geschlecht:
                                </v-col>
                                <v-col cols="7">
                                    <select
                                        v-model="parentGender"
                                        class="input"
                                        :class="{ errorInput: fields.parentGender }"
                                        @change="validateInput"
                                    >
                                        <option
                                            disabled
                                            selected
                                            value
                                        >
                                            Auswählen
                                        </option>
                                        <option value="m">
                                            Männlich
                                        </option>
                                        <option value="w">
                                            Weiblich
                                        </option>
                                        <option value="d">
                                            Divers
                                        </option>
                                        <option value="">
                                            Keine Angabe
                                        </option>
                                    </select>
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.parentGender"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Pflichtfeld</span>
                                </v-col>
                            </v-row>
                            <v-row />
                        </v-col>
                        <v-col class="mx-5">
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Kontaktinformationen
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    Telefonnummer:
                                </v-col>
                                <v-col cols="7">
                                    <input
                                        v-model="parentPhone"
                                        type="text"
                                        class="input"
                                        placeholder="Optional"
                                    >
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="fields.parentPhone && parentPhone.length !== 0"
                                dense
                            >
                                <v-col
                                    class="pt-0"
                                    style="margin-top: -10px"
                                >
                                    <span style="color: red; font-size: 12px">Telefonnummer darf keine Buchstaben enthalten.</span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="12"
                            md="3"
                            class="mx-5"
                        >
                            <v-row>
                                <v-col>
                                    <div
                                        class="font-weight-bold"
                                        style="color: rgba(0, 0, 0, 0.6)"
                                    >
                                        Technisches
                                    </div>
                                </v-col>
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="makeParentPassword"
                                    >
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Login-ID*:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editParentLoginId = true;"
                                >
                                    <input
                                        v-model="parentLoginId"
                                        type="text"
                                        class="input"
                                        :class="{ errorInput: fields.parentLoginId || fields.parentLoginIdDuplicate }"
                                        :disabled="!editParentLoginId || pupilHasParent"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="fields.parentLoginId"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID darf nur Zahlen und Buchstaben enthalten.</span>
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="fields.parentLoginIdDuplicate"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Login-ID wird bereits verwendet.</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="5">
                                    Passwort:
                                </v-col>
                                <v-col
                                    cols="7"
                                    @click="editParentPassword = true"
                                >
                                    <input
                                        v-model="parentPassword"
                                        :type="editParentPassword ? 'text' : (oldProfile && !editParentPassword) ? 'password' : 'text'"
                                        :disabled="!editParentPassword"
                                        class="input"
                                        :class="{ errorInput: fields.parentPassword }"
                                        @change="validateInput"
                                    >
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="fields.parentPassword"
                                dense
                            >
                                <v-col style="padding-top: 0; margin-top: -10px">
                                    <span style="color: red; font-size: 12px">Passwort erfüllt Richtlinie nicht.</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pt-0"
                                >
                                    <p
                                        class="mb-2"
                                        style="color: #808080;"
                                    >
                                        Muss mind. 8 Zeichen lang sein und einen Buchstaben und eine Zahl enthalten.
                                    </p>
                                </v-col>
                            </v-row>

                            <v-row v-if="pupilHasParent">
                                <v-col>
                                    <v-btn
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="setDeleteDialog('parent')"
                                    >
                                        <img :src="loeschIcon"> Eltern-Account löschen
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <!-- are these even used anymore? -->
                            <v-row>
                                <v-col cols="7">
                                    <v-btn
                                        v-if="false"
                                        class="generatePasswordButton"
                                        elevation="0"
                                        icon
                                        @click="makeParentPassword"
                                    >
                                        <img :src="aktualisierenIcon">Passwort generieren
                                    </v-btn>
                                </v-col>
                                <v-col cols="5">
                                    <v-btn
                                        v-if="false"
                                        class=""
                                        style="max-height: 25px;max-width: 100%; width: 100%; border-radius: 8px; background-color: rgba(230,34,30,0.41)"
                                        color=""
                                        small
                                        light
                                        @click="deleteDialog = true"
                                    >
                                        Löschen
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-spacer class="mt-1" />
                        </v-col>
                    </v-row>
                </div>
            </div>
        </v-row>

        <!-- delete dialog -->
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    {{ deleteDialogTitle }}
                </v-card-title>
                <v-card-text>{{ deleteDialogText }}</v-card-text>

                <!-- delete pupil account -->
                <v-card-actions v-if="deleteDialogSubject === 'pupil'">
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deleteUser(); deleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>

                <!-- delete parent account -->
                <v-card-actions v-else-if="deleteDialogSubject === 'parent'">
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="red red-1"
                        text
                        @click="() => {deletePupilParent(); deleteDialog = false}"
                    >
                        Löschen
                    </v-btn>
                </v-card-actions>

                <!-- just in case the dialog is called somewhere without the deleteDialogSubject -->
                <v-card-actions v-else>
                    <v-spacer />
                    <v-btn
                        color=""
                        text
                        @click="deleteDialog = false"
                    >
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- create new account dialog -->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Accountdaten herunterladen?
                </v-card-title>
                <v-card-text>
                    Ein Account wurde mit folgenden Daten angelegt. An erster Stelle sehen Sie das Passwort, welches für
                    den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an den Benutzer
                    aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im
                    Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen
                    kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{ password }}
                        </v-row>
                        <!--                        <v-row>-->
                        <!--                            QR-Passwort: {{qrPassword}}-->
                        <!--                        </v-row>-->
                        <v-row>
                            Login-ID: {{ userName }}
                        </v-row>
                        <v-row>
                            Vorname: {{ name }}
                        </v-row>
                        <v-row>
                            Nachname: {{ lastName }}
                        </v-row>
                        <v-row>
                            Geschlecht: {{ gender }}
                        </v-row>
                        <v-row>
                            Notiz: {{ note }}
                        </v-row>
                        <v-row
                            class="mt-1"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodecontainer"
                                    :value="qrCodeData"
                                    size="200"
                                />
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodeparentcontainer"
                                    :value="parentQrCodeData"
                                    size="200"
                                />

                                <v-row
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="pdf()"
                                        >
                                            QR Download
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols=""
                                        class="pa-0 mt-2"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="kuhR()"
                                        >
                                            Kuh-R Bastelvorlage
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <!-- only show parent download when creating new parent account, otherwise you can't get the pw as it's only stored encrypted -->
                                <v-row
                                    v-if="createNewParent"
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0 mt-2"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="parentPdf()"
                                        >
                                            Eltern-PDF Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        dark
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- edit account dialog -->
        <v-dialog
            v-model="dialog2"
            persistent
            max-width="400"
        >
            <v-card>
                <v-card-title class="headline">
                    Accountdaten herunterladen?
                </v-card-title>
                <v-card-text>
                    Ein Account wurde mit folgenden Daten geändert. An erster Stelle sehen Sie das Passwort, welches für
                    den neuen Benutzer generiert wurde. Bitte notieren Sie dieses und händigen Sie es an dem Benutzer
                    aus.

                    <br> <br>
                    Dieses Passwort wird später nicht mehr angezeigt und kann auch nicht wiederhergestellt werden. Im
                    Zweifelsfall müssen Sie dem Benutzer ein neues Passwort zuweisen, wenn er sich nicht mehr einloggen
                    kann.

                    <br> <br>
                    <div class="ml-3">
                        <v-row>
                            Passwort: {{ password }}
                        </v-row>
                        <!--                        <v-row>
                            QR-Passwort: {{qrPassword}}
                        </v-row>-->
                        <v-row>
                            Login-ID: {{ userName }}
                        </v-row>
                        <v-row>
                            Vorname: {{ name }}
                        </v-row>
                        <v-row>
                            Nachname: {{ lastName }}
                        </v-row>
                        <v-row>
                            Geschlecht: {{ gender }}
                        </v-row>
                        <v-row>
                            Notiz: {{ note }}
                        </v-row>
                        <v-row
                            class="mt-1"
                            align="center"
                            justify="center"
                        >
                            <div style="width: 200px">
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodecontainer"
                                    :value="qrCodeData"
                                    size="200"
                                />
                                <qrcode-vue
                                    v-show="false"
                                    ref="qrcodeparentcontainer"
                                    :value="parentQrCodeData"
                                    size="200"
                                />

                                <v-row
                                    v-if="newPassword"
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="pdf()"
                                        >
                                            QR Download
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols=""
                                        class="pa-0 mt-2"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="kuhR()"
                                        >
                                            Kuh-R Bastelvorlage
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row
                                    v-if="newParentPassword || createNewParent"
                                    style="width: 200px; text-align-last: center;"
                                    class="ma-0"
                                >
                                    <v-col
                                        cols="12"
                                        style=""
                                        class="pa-0 mt-2"
                                    >
                                        <v-btn
                                            class=""
                                            style=""
                                            @click="parentPdf()"
                                        >
                                            Eltern-PDF Download
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="green darken-1"
                        dark
                        @click="closeDialogs"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <qrcode-vue
            v-show="false"
            ref="hiddenqrcodecontainer"
            :value="qrCodeData"
            size="200"
        />

        <input
            id="exelUploadInput"
            ref="exelUploadInput"
            type="file"
            hidden
            @change="() => exelUploadInputChange()"
        >
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import suchIcon from "../../assets/Icons/suche-26.svg";
import pupilIcon from "../../assets/Icons/profile.svg";
import hochladen from "../../assets/Icons/hochladen-15.svg";
import bearbeitenIcon from "../../assets/Icons/bearbeiten-normal-weiß-41.svg";
import infoIcon from "../../assets/Icons/ausrufezeichen-62.svg";
import loeschIcon from "../../assets/Icons/papierkorb-verwaltung-109.svg";
import pdfDocIcon from "../../assets/Icons/PDF-65.svg";
import pdfFile from "../../assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfParentFile from "../../assets/PDF/Eltern-QR-Vorlage.pdf";
import kuhRFile from "../../assets/PDF/Faltkuh.pdf";
import aktualisierenIcon from "../../assets/Icons/aktualisieren-107.svg";
import roboto from "../../assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "../../assets/Fonts/Roboto-Bold.ttf";

import easy from "../../assets/einfache-Ansicht.svg";
import hard from "../../assets/normale-Ansicht.svg";

import generateRandomPassword from "../../util/generateRandomPassword";

import QrcodeVue from "qrcode.vue";

import {PDFDocument, rgb, StandardFonts} from "pdf-lib";
import * as xlsx from "xlsx";
import JSZip from "jszip";
import {saveAs} from "file-saver";
import {compress} from "shrink-string";
import fontkit from "@pdf-lib/fontkit";

export default {
    name: "Schueler",
    components: {
        QrcodeVue,
    },
    data() {
        return {
            showLoadingOverlay: false,
            newPassword: null,
            newParentPassword: null,
            oldProfile: null,
            dialog: false,
            dialog2: false,
            dialog3: false,
            debugMode: true,

            name: "",
            lastName: "",

            gender: "",
            note: "",
            babyView: false,
            sehen: false,
            screenreader: false,
            hoeren: false,
            motorik: false,
            userName: "",
            password: "",
            qrPassword: "",
            groups: [],

            parentGender: "",
            parentPhone: "",
            parentLoginId: "",
            parentPassword: "",
            parentId: "",
            parents: [],
            selectedParent: "",
            createNewParent: false,
            pupilHasParent: false,

            editPassword: false,
            editParentPassword: false,
            editLoginId: false,
            editParentLoginId: false,
            deleteDialog: false,
            fields: {
                name: false,
                lastName: false,

                gender: false,
                userName: false,
                password: false,
                parent: false,
                parentGender: false,
                parentPhone: false,
                parentLoginId: false,
                parentLoginIdDuplicate: false,
                parentPassword: false,
            },
            height: 0,
            search: "",
            headers: [
                {text: "Name", value: "lastName"},
                {text: "Vorname", value: "name"},

                {text: "Geschlecht", value: "gender"},
                {text: "", value: "name", sortable: false},
            ],
            list: [],

            deleteDialogTitle: '',
            deleteDialogText: '',
            deleteDialogSubject: '',

            suchIcon,
            pupilIcon,
            bearbeitenIcon,
            pdfFile,
            pdfParentFile,
            kuhRFile,
            easy,
            hard,
            roboto,
            robotoBold,
            infoIcon,
            loeschIcon,
            pdfDocIcon,
            aktualisierenIcon,
            hochladen,
            qrCodeData: "{ name: '', pw: '' }",
            parentQrCodeData: "{ name: '', pw: '' }",
        };
    },
    computed: {
        ...mapGetters("pupils", ["getPupilListStatus"]),
        isUsernameFree() {
            for (let i = 0; i < this.list.length; i++) {
                let pupil = this.list[i];
                if (this.userName === pupil.account.accountName) {
                    return false;
                }
            }

            return true;
        },
    },
    watch: {
        userName: function () {
            this.qrcodeString();
        },
        password: function () {
            this.qrcodeString();
        },
        parentLoginId: function () {
            this.parentQrcodeString();
        },
        parentPassword: function () {
            this.parentQrcodeString();
        },
        getPupilListStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestPupils();
            }
        },
    },
    async created() {
        this.height = window.innerHeight * 0.3;
    },
    async mounted() {
        this.showLoadingOverlay = true;
        this.makePassword();
        this.makeParentPassword();
        this.requestPupils();
        this.requestParents();

        if (this.$route.query.debug) {
            this.debugMode = true;
        }
    },
    methods: {
        ...mapActions("groups", ["getGroup", "getGroups"]),
        ...mapActions("pupils", ["createPupil", "getPupil", "getPupils", "editPupil", "deletePupile", "setPupilListStatus"]),
        ...mapActions("parent", ["getParents", "getParent", "createParent", "editParent", "deleteParent"]),
        ...mapActions("auth", ["isLoginIdAvailable"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async exelUploadInputChange() {
            this.showLoadingOverlay = true;
            const input = this.$refs.exelUploadInput;
            const file = input.files[0];
            if (!file) {
                return;
            }
            if (
                file.type !==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ) {
                this.showSnackbar({
                    message: 'Dateiformat nicht zugelassen. Bitte Excel-Datei auswählen! (".xlsx")',
                    color: 'error'
                });
                return;
            }
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e) => {
                let data = new Uint8Array(reader.result);
                let workbook = xlsx.read(data, {type: "array"});

                // Excel accepts "Männlich" as a valid value
                // but the backend only accepts "m" for the male gender
                const mapXlsxGenderToBackendGender = (gender) => {
                    switch (gender) {
                        case "Männlich":
                        case "m":
                            return "m";
                        case "Weiblich":
                        case "w":
                            return "w";
                        default:
                            // Divers
                            return "d";
                    }
                };

                const existingAccounts = this.list.map((pupil) => pupil.account);

                const noSpecialChar = new RegExp("[^0-9a-zA-Z]+");
                // Convert workbook to array of users to import
                const xlsxUsers = xlsx.utils
                    .sheet_to_json(workbook.Sheets["Main"])
                    .map((row) => ({
                        // Get user data
                        accountName: row["Benutzername"].replace(noSpecialChar, ""),
                        name: row["Vorname"],
                        lastName: row["Nachname"],
                        gender: mapXlsxGenderToBackendGender(row["Geschlecht"]),
                        babyView: row["Einfache Ansicht"] === "Ja",
                        password: row["Passwort"] || generateRandomPassword({length: 8}),
                        qrPassword: generateRandomPassword({length: 12}),
                        note: row["Notiz"] || "",
                        rowNumber: row["__rowNum__"],
                    }))
                    .map((user) => {
                        // Validate input data and if already exist on server
                        const checkAccountnameValid =
                            typeof user.accountName === "string" &&
                            user.accountName.length > 0;
                        const checkNameValid =
                            typeof user.name === "string" && user.name.length > 0;
                        const checkLastNameValid =
                            typeof user.lastName === "string" && user.lastName.length > 0;
                        const checkPasswordValid =
                            typeof user.password &&
                            user.password.length >= 8 &&
                            /\d/.test(user.password) && // Contains number
                            /[A-Za-z]/.test(user.password); // Contains character
                        return {
                            ...user,
                            invalidData: !(
                                checkAccountnameValid &&
                                checkNameValid &&
                                checkLastNameValid &&
                                checkPasswordValid
                            ),
                            alreadyExistsOnServer: existingAccounts.some(
                                (existingAccount) =>
                                    existingAccount.accountName === user.accountName
                            ),
                        };
                    });


                const usersToImport = xlsxUsers.filter(
                    (user) => !user.alreadyExistsOnServer && !user.invalidData
                );

                const importResponses = await usersToImport.reduce(
                    async (importedUsersPromise, user) => {
                        const importedUsers = await importedUsersPromise;
                        try {
                            const pupil = await this.createPupil(user);

                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    importedUser: pupil,
                                },
                            ];
                        } catch (error) {
                            console.error(error);

                            return [
                                ...importedUsers,
                                {
                                    userToImport: user,
                                    error,
                                },
                            ];
                        }
                    },
                    Promise.resolve([])
                );

                const usersToGeneratePDF = importResponses.filter(
                    (importResponse) => !importResponse.error
                );

                let pdfUsers = [];
                for (const user of usersToGeneratePDF) {
                    const {userToImport} = user;
                    const compressedName = await compress(userToImport.accountName);
                    const compressedPW = await compress(userToImport.password);
                    const compressedString = JSON.stringify({
                        name: compressedName,
                        pw: compressedPW,
                    });

                    const pdfBytes = await this.getPDFBytes({
                        ...userToImport,
                        qrCodeData: compressedString,
                    });
                    pdfUsers.push({
                        userToImport,
                        pdfBytes,
                    });
                }


                const zip = new JSZip();

                // https://stackoverflow.com/a/10073788
                // Add padding to numbers that are too short like 43 => 0043
                const pad = (n, width, z) => {
                    z = z || "0";
                    n = n + "";
                    return n.length >= width
                        ? n
                        : new Array(width - n.length + 1).join(z) + n;
                };
                pdfUsers.forEach((pdfUser) => {
                    const pdfName =
                        pad(pdfUser.userToImport.rowNumber, 3) +
                        "_" +
                        pdfUser.userToImport.accountName +
                        ".pdf";
                    zip.file(pdfName, pdfUser.pdfBytes);
                });
                // server side error during import
                const failedImports = importResponses.filter(
                    (importResponse) => importResponse.error
                );
                // invalid userdata or existing User
                const badSubmissions = xlsxUsers.filter(
                    (user) => user.alreadyExistsOnServer || user.invalidData
                );
                let failArray = [];
                if (failedImports.length > 0) {
                    failArray = failArray.concat(failedImports.map((importResponse) => ({
                        Zeile: importResponse.userToImport.rowNumber,
                        Fehler: importResponse.error,
                    })));
                }
                if (badSubmissions.length > 0) {
                    console.log('badSubmissions', badSubmissions);
                    failArray = failArray.concat(badSubmissions.map((submission) => ({
                        Zeile: submission.rowNumber,
                        Person: `Benutzername: ${submission.accountName}, Vorname: ${submission.name}, Nachname: ${submission.lastName}.`,
                        Fehler: 'Bitte überprüfen Sie die Daten auf Regelkonformität (zb. Sonderzeichen, Passwort enthält Zahl und Buchstaben). Der Benutzername muss eindeutig sein und darf nicht bereits existieren.',
                    })));
                }
                if (failedImports.length > 0 || badSubmissions.length > 0) {
                    let blob = new Blob([JSON.stringify(failArray, null, '\t')], {
                        type: "text/plain;charset=utf-8",
                    });
                    zip.file("Fehler.txt", blob);
                }

                zip.generateAsync({type: "blob"}).then(function (content) {
                    // see FileSaver.js
                    saveAs(content, "Importierte_Schüler.zip");
                });
                await this.requestPupils();
            };
        },
        clickExelUploadButton() {
            document.getElementById("exelUploadInput").click();
        },
        async openDialog() {
            if (this.editPassword) {
                this.newPassword = this.password;
            }
            if (this.editParentPassword) {
                this.newParentPassword = this.parentPassword;
            }

            if (!(await this.validateInput())) {
                this.showSnackbar({message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                return;
            }

            if (!this.oldProfile) {
                // when creating a new account
                this.saveUser();
                this.dialog = true;
            } else if (!this.newPassword && !this.newParentPassword) {
                // when changing stuff other than password
                await this.patchUser();
                this.stopEdit();
            } else {
                // when changing password
                this.patchUser();
                this.dialog2 = true;
            }
        },
        stopEdit() {
            this.pupilHasParent = false;
            this.oldProfile = null;
            this.name = "";
            this.lastName = "";

            this.babyView = false;
            this.sehen = false;
            this.screenreader = false;
            this.hoeren = false;
            this.motorik = false;
            this.gender = "";
            this.note = "";
            this.userName = "";
            this.parentGender = "";
            this.parentPhone = "";
            this.parentPassword = "";
            this.parentLoginId = "";
            this.makePassword();
            this.groups = [];
            this.makeParentPassword();

            this.fields.name = false;
            this.fields.lastName = false;
            this.fields.gender = false;

            this.fields.userName = false;
            this.fields.password = false;
            this.fields.parent = false;
            this.fields.parentGender = false;
            this.fields.parentPhone = false;
            this.fields.parentPassword = false;
            this.fields.parentLoginId = false;
            this.fields.parentLoginIdDuplicate = false;

            this.editPassword = false;
            this.editLoginId = false;
            this.editParentLoginId = false;
            this.editParentPassword = false;

            this.createNewParent = false;
            this.selectedParent = null;
        },
        async editUser(profile) {
            this.$vuetify.goTo("#edit");
            this.groups = [];
            this.oldProfile = profile;
            this.name = profile.name;
            this.lastName = profile.lastName;

            this.gender = profile.gender;
            this.note = profile.note;
            this.babyView = profile.babyView;
            this.userName = profile.account.accountName;
            this.qrPassword = profile.qrPassword || this.qrPassword;
            this.newPassword = null;
            this.parentNewPassword = null;
            this.insertGroupNames(profile.account.groups);

            if (profile.accessibility) {
                this.sehen = profile.accessibility.sehen;
                this.screenreader = profile.accessibility.screenreader;
                this.hoeren = profile.accessibility.hoeren;
                this.motorik = profile.accessibility.motorik;
            } else {
                this.sehen = false;
                this.screenreader = false;
                this.hoeren = false;
                this.motorik = false;
            }

            this.pupilHasParent = false;
            this.parentGender = "";
            this.parentPhone = "";
            this.parentLoginId = "";
            this.parentId = "";

            if (profile.parent) {
                let parent = await this.getParent(profile.parent);
                if (parent) {

                    this.pupilHasParent = true;

                    this.parentGender = parent.gender;
                    this.parentPhone = parent.phone;
                    this.parentLoginId = parent.account.accountName;
                    this.parentId = parent._id;
                }
            }

            this.editPassword = false;
            this.editLoginId = false;
            this.editParentPassword = false;
            this.editParentLoginId = false;
        },

        //delete parent of a pupil
        async deletePupilParent() {
            //this method will only be callable when the user has a parent, so this.parentId will be set
            await this.deleteParent(this.parentId);
            this.pupilHasParent = false;

            await this.requestPupils();
            this.setPupilListStatus(true);

            this.parentGender = "";
            this.parentPhone = "";
            this.parentPassword = "";
            this.parentLoginId = "";
            this.makeParentPassword();

            this.createNewParent = false;
            this.selectedParent = null;

            this.showSnackbar({message: 'Gespeichert!'});
        },

        async deleteUser() {
            try {
                this.showLoadingOverlay = true;

                let response = await this.deletePupile(this.oldProfile._id);
                if (response) {
                    const index = this.list.indexOf(this.oldProfile);
                    if (index > -1) {
                        this.list.splice(index, 1);
                    }

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    this.stopEdit();
                    this.makePassword();
                    this.makeParentPassword();
                    this.showSnackbar({message: 'Gespeichert!'});
                } else {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                }
                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },

        async kuhR() {
            const existingPdfBytes = await fetch(this.kuhRFile).then((res) =>
                res.arrayBuffer()
            );

            const qrCode = this.$refs.qrcodecontainer.$refs["qrcode-vue"].toDataURL(
                "image/png"
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);
            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);
            // Embed the Helvetica font
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoFont = await pdfDoc.embedFont(robotoBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            firstPage.drawImage(pngImage, {
                x: 338,
                y: 522,
                width: 82,
                height: 82,
            });

            firstPage.drawImage(pngImage, {
                x: 338,
                y: 335,
                width: 82,
                height: 82,
            });

            firstPage.drawText(this.name + " " + this.lastName.slice(0, 1) + ".", {
                x: 180,
                y: 733,
                size: 16,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 180,
                y: 695,
                size: 12,
                font: robotoFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray(this.name + "-kuhR.pdf", pdfBytes);
        },

        async pdf() {
            const existingPdfBytes = await fetch(this.pdfFile).then((res) =>
                res.arrayBuffer()
            );

            const qrCode = this.$refs.qrcodecontainer.$refs["qrcode-vue"].toDataURL(
                "image/png"
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm();
            const button = form.createButton("some.button.field");

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();

            // Draw a string of text diagonally across the first page
            firstPage.drawText(this.name + " " + this.lastName, {
                x: 100.7,
                y: 670,
                size: 11,
                font: robotoFont,
            });

            firstPage.drawText(this.name + ",", {
                x: 168,
                y: 536,
                size: 11,
                font: robotoFont,
            });

            firstPage.drawText(this.userName, {
                x: 100.7,
                y: 412,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(this.password, {
                x: 100.7,
                y: 368,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 100.7,
                y: 325,
                size: 11,
                font: robotoBoldFont,
            });

            //QR1
            firstPage.drawImage(pngImage, {
                x: 47,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 200,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.userName, {
                x: 200,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 200,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 200,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 42,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 102,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            //QR2
            firstPage.drawImage(pngImage, {
                x: 330,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 482,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.userName, {
                x: 482,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 482,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 482,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 325,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 385,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray(this.userName + ".pdf", pdfBytes);
        },

        async parentPdf() {
            const existingPdfBytes = await fetch(this.pdfParentFile).then((res) =>
                res.arrayBuffer()
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const qrCodeParent = this.$refs.qrcodeparentcontainer.$refs[
                "qrcode-vue"
                ].toDataURL("image/png");
            const pngImage = await pdfDoc.embedPng(qrCodeParent);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm();
            const button = form.createButton("some.button.field");

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();

            firstPage.drawText(this.parentLoginId, {
                x: 100.7,
                y: 403,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(this.parentPassword, {
                x: 100.7,
                y: 358,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 100.7,
                y: 314,
                size: 11,
                font: robotoBoldFont,
            });

            //QR1
            firstPage.drawImage(pngImage, {
                x: 47,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 200,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.parentLoginId, {
                x: 200,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 200,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.parentPassword, {
                x: 200,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 42,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 102,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            //QR2
            firstPage.drawImage(pngImage, {
                x: 330,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 482,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.parentLoginId, {
                x: 482,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 482,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.parentPassword, {
                x: 482,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 325,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 385,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray(this.parentLoginId + ".pdf", pdfBytes);
        },

        async getPDFBytes(pupil) {
            this.qrCodeData = pupil.qrCodeData;
            const existingPdfBytes = await fetch(this.pdfFile).then((res) =>
                res.arrayBuffer()
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm();
            const button = form.createButton("some.button.field");

            const qrCode = this.$refs.hiddenqrcodecontainer.$refs[
                "qrcode-vue"
                ].toDataURL("image/png");
            const pngImage = await pdfDoc.embedPng(qrCode);

            // Get the width and height of the first page
            const {width, height} = firstPage.getSize();

            // Draw a string of text diagonally across the first page
            firstPage.drawText(pupil.name + ",", {
                x: 168,
                y: 536,
                size: 11,
                font: robotoFont,
            });

            firstPage.drawText(pupil.accountName, {
                x: 100.7,
                y: 412,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(pupil.password, {
                x: 100.7,
                y: 368,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 100.7,
                y: 325,
                size: 11,
                font: robotoBoldFont,
            });

            //QR1
            firstPage.drawImage(pngImage, {
                x: 47,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 200,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(pupil.accountName, {
                x: 200,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 200,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(pupil.password, {
                x: 200,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 42,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 102,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            //QR2
            firstPage.drawImage(pngImage, {
                x: 330,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 482,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(pupil.accountName, {
                x: 482,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 482,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(pupil.password, {
                x: 482,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 325,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 385,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            return pdfBytes;
        },

        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], {type: "application/pdf"});
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            let fileName = reportName;
            link.download = fileName;
            link.click();
        },

        async validateInput() {
            let result = true;

            this.fields.name = false;
            this.fields.lastName = false;
            this.fields.gender = false;

            this.fields.userName = false;

            //info: parent is not required
            this.fields.parent = false;
            this.fields.parentPhone = false;
            this.fields.parentGender = false;
            this.fields.parentLoginId = false;
            this.fields.parentPassword = false;

            this.parentLoginIdDuplicate = false;

            if (
                this.name.replace(/\s/g, "") === "" ||
                this.containsNameNumbers(this.name)
            ) {
                this.fields.name = true;
                result = false;
            }
            if (
                this.lastName.replace(/\s/g, "") === "" ||
                this.containsNameNumbers(this.lastName)
            ) {
                this.fields.lastName = true;
                result = false;
            }
            if (
                this.userName.replace(/\s/g, "") === "" ||
                (!this.isUsernameFree && !this.oldProfile) ||
                this.containsUsernameStrangeLetters(this.userName) ||
                this.userName.trim().indexOf(" ") > -1
            ) {
                this.fields.userName = true;
                result = false;
            }

            if (this.editPassword) {
                this.fields.password = false;
                if (!this.checkPw()) {
                    this.fields.password = true;
                    result = false;
                }
            }

            if (this.pupilHasParent) {
                //all fine, skip the next two elses
            } else if (this.createNewParent) {
                if (this.parentPhone !== "") {
                    if (
                        this.parentPhone.replace(/\s/g, "") === "" ||
                        this.containsPhonenumberLetters(this.parentPhone)
                    ) {
                        this.fields.parentPhone = true;
                        result = false;
                    }
                }
                if (
                    this.parentLoginId.replace(/\s/g, "") === "" ||
                    this.containsUsernameStrangeLetters(this.parentLoginId)
                ) {
                    this.fields.parentLoginId = true;
                    result = false;
                }
                //if neither a existing parent is selected nor the checkbox for new parent is checked
            } /*else if (!this.createNewParent && !this.selectedParent) {

                this.fields.parent = true;
                result = false;
            }*/

            if (this.createNewParent) {
                let available = await this.checkIdAvailability(this.parentLoginId);
                if (!available) {
                    this.fields.parentLoginIdDuplicate = true;
                    result = false;
                } else {
                    this.fields.parentLoginIdDuplicate = false;
                }
            }

            if (this.editParentPassword) {
                this.fields.parentPassword = false;
                if (!this.checkParentPw()) {
                    this.fields.parentPassword = true;
                    result = false;
                }
            }

            return result;
        },
        containsNameNumbers(name) {
            // eslint-disable-next-line no-useless-escape
            const format = /[0-9]+/;
            return format.test(name);
        },
        containsUsernameStrangeLetters(username) {
            // eslint-disable-next-line no-useless-escape
            const format = /[!@#$äüö%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            return format.test(username);
        },
        containsPhonenumberLetters(phonenumber) {
            // eslint-disable-next-line no-useless-escape
            const format = /[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]+/;
            return format.test(phonenumber);
        },

        // info: parent is not required
        async patchUser() {
            try {
                this.showLoadingOverlay = true;

                let parentIdPupil, responseParent;

                // create a new parent account if the pupil already has an account and checkbox for creating new parent is ticked
                // create parent first to save reference to parent in pupil account
                if (this.createNewParent && this.oldProfile) {
                    let parentData = {
                        gender: this.parentGender,
                        phone: this.parentPhone,
                        accountName: this.parentLoginId.trim(),
                        password: this.parentPassword,
                    };
                    responseParent = await this.createParent(parentData);
                    parentIdPupil = responseParent["_id"];

                    if (Number.isInteger(responseParent)) {
                        if (responseParent === 409) {
                            this.showSnackbar({
                                message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                                color: 'error'
                            });
                        } else {
                            this.showSnackbar({
                                message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                                color: 'error'
                            });
                        }
                    } else {
                        responseParent["accountName"] = this.parentLoginId.trim();
                    }
                    // use parent account chosen in dropdown
                } else if (this.selectedParent) {
                    let parentAcc = this.parents.filter(this.filterParents);
                    parentIdPupil = parentAcc[0]._id;
                }

                // changed pupil data
                let data = {
                    _id: this.oldProfile._id,
                    name: this.name,
                    lastName: this.lastName,
                    babyView: this.babyView,
                    accessibility: {
                        sehen: this.sehen,
                        screenreader: this.screenreader,
                        hoeren: this.hoeren,
                        motorik: this.motorik,
                    },
                    gender: this.gender,
                    note: this.note,
                };

                if (this.createNewParent || this.selectedParent) {
                    data["parent"] = parentIdPupil;
                }
                if (this.newPassword) {
                    data["password"] = this.newPassword;
                    data["qrPassword"] = this.qrPassword;
                }
                if (this.userName !== this.oldProfile.account.accountName) {
                    data["accountName"] = this.userName.trim();
                }
                let response = await this.editPupil(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({
                            message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                            color: 'error'
                        });
                    } else {
                        this.showSnackbar({
                            message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                            color: 'error'
                        });
                    }
                } else {
                    response["accountName"] = this.userName.trim();

                    const index = this.list.indexOf(this.oldProfile);
                    if (index > -1) {
                        this.list.splice(index, 1);
                    }
                    this.list.push(response);

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    this.showSnackbar({message: 'Gespeichert!'});
                }

                let pupils = [];
                let parentResponse, parentAcc;

                // if pupil already has parent
                if (this.oldProfile.parent) {
                    parentAcc = await this.getParent(this.oldProfile.parent);
                } else {
                    // else get parent chosen from dropdown and save parentId
                    let temp = this.parents.filter(this.filterParents);
                    parentAcc = temp[0];
                }

                if (!this.createNewParent && this.oldProfile) {
                    pupils = parentAcc.pupils;
                    pupils.push(response["_id"]);
                    this.parentId = parentAcc._id;

                    // patch parent
                    let parentData = {
                        _id: this.parentId,
                        gender: this.parentGender,
                        phone: this.parentPhone,
                        pupils: pupils,
                    };
                    if (this.editParentPassword) {
                        parentData["password"] = this.newParentPassword;
                    }
                    parentResponse = await this.editParent(parentData);

                    // create new parent account, this pupil will be it's first kid
                } else if (this.createNewParent) {
                    pupils.push(this.oldProfile._id); // this is the id of the already existing pupil acc

                    //update parent account with pupil
                    let parentUpdate = {
                        _id: responseParent["_id"],
                        pupils: pupils,
                    };
                    parentResponse = await this.editParent(parentUpdate);
                }

                if (Number.isInteger(parentResponse)) {
                    if (parentResponse === 409) {
                        this.showSnackbar({
                            message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                            color: 'error'
                        });
                    } else {
                        this.showSnackbar({message: 'Ein unerwarteter Fehler ist aufgetreten!', color: 'error'});
                    }
                } else {
                    parentResponse["accountName"] = this.parentLoginId.trim();

                    this.requestPupils();
                    this.setPupilListStatus(true);

                    this.showSnackbar({message: 'Gespeichert!'});
                }
                await this.requestParents();

                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },

        filterParents(value) {
            return value.account.accountName === this.selectedParent;
        },

        // info: parent is not required
        async saveUser() {
            try {
                this.showLoadingOverlay = true;

                let parentIdPupil;

                // create a new parent account, create first to save reference to parent in pupil account
                if (this.createNewParent) {
                    let parentData = {
                        gender: this.parentGender,
                        phone: this.parentPhone,
                        accountName: this.parentLoginId.trim(),
                        password: this.parentPassword,
                    };
                    let responseParent = await this.createParent(parentData);
                    parentIdPupil = responseParent["_id"];

                    if (Number.isInteger(responseParent)) {
                        if (responseParent === 409) {
                            this.showSnackbar({
                                message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                                color: 'error'
                            });
                        } else {
                            this.showSnackbar({
                                message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                                color: 'error'
                            });
                        }
                    } else {
                        responseParent["accountName"] = this.parentLoginId.trim();
                    }
                } else if (this.selectedParent) {
                    // update chosen parent account, if one is chosen in dropdown
                    //get that parent account
                    let parentAcc = this.parents.filter(this.filterParents);
                    parentIdPupil = parentAcc[0]._id;
                }

                let response;

                // create pupil
                let data = {
                    name: this.name,
                    lastName: this.lastName,
                    gender: this.gender,
                    note: this.note,
                    babyView: this.babyView,
                    accessibility: {
                        sehen: this.sehen,
                        screenreader: this.screenreader,
                        hoeren: this.hoeren,
                        motorik: this.motorik,
                    },
                    accountName: this.userName.trim(),
                    password: this.password,
                    qrPassword: this.qrPassword,
                    parent: parentIdPupil,
                };
                response = await this.createPupil(data);
                if (Number.isInteger(response)) {
                    if (response === 409) {
                        this.showSnackbar({
                            message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                            color: 'error'
                        });
                    } else {
                        this.showSnackbar({
                            message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                            color: 'error'
                        });
                    }
                } else {
                    response["accountName"] = this.userName.trim();
                    this.list.push(response);
                }

                // add children to parent
                let pupils = [],
                    parentId;

                if (!this.createNewParent) {
                    // use existing parent account
                    // get the parent account chosen in dropdown
                    let parentAcc = this.parents.filter(this.filterParents);
                    let parent = parentAcc[0];

                    pupils = parent.pupils;
                    pupils.push(response["_id"]);
                    parentId = parent._id;
                } else if (this.createNewParent) {
                    // use newly created parent account
                    pupils.push(response["_id"]);
                    parentId = parentIdPupil;
                }

                // if a parent account has been created update it with pupil reference
                if (this.createNewParent || this.selectedParent) {
                    let parentUpdate = {
                        _id: parentId,
                        pupils: pupils,
                    };
                    let responseUpdate = await this.editParent(parentUpdate);

                    if (Number.isInteger(responseUpdate)) {
                        if (responseUpdate === 409) {
                            this.showSnackbar({
                                message: 'Login-ID bereits vergeben. Bitte geben Sie eine andere Login-ID ein!',
                                color: 'error'
                            });
                        } else {
                            this.showSnackbar({
                                message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                                color: 'error'
                            });
                        }
                    } else {
                        this.requestPupils();
                        this.setPupilListStatus(true);
                        await this.requestParents();

                        this.showSnackbar({message: 'Gespeichert!'});
                    }
                }

                this.showLoadingOverlay = false;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        closeDialogs() {
            this.stopEdit();
            this.makePassword();
            this.makeParentPassword();

            this.editPassword = false;
            this.editLoginId = false;
            this.createNewParent = false;
            this.editParentLoginId = false;
            this.editParentPassword = false;

            this.dialog2 = false;
            this.dialog3 = false;
            this.dialog = false;
        },

        async requestPupils() {
            this.showLoadingOverlay = true;
            this.list = await this.getPupils();
            this.showLoadingOverlay = false;
            this.setPupilListStatus(false);
        },
        makePassword() {
            let result = "";
            let qrResult = "";
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let numbers = "0123456789";
            let numberCount = Math.floor(Math.random() * 6 + 1);

            for (let i = 0; i < 8 - numberCount; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            for (let i = 0; i < numberCount; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbers.length));
            }
            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            this.password = result;
            this.newPassword = result;
            this.qrPassword = qrResult;
        },
        makeParentPassword(event) {
            let result = "";
            let qrResult = "";
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
            let numbers = "0123456789";
            let numberCount = Math.floor(Math.random() * 6 + 1);

            for (let i = 0; i < 8 - numberCount; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            for (let i = 0; i < numberCount; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbers.length));
            }
            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            this.parentPassword = result;

            if (!this.editParentPassword && event) {
                this.showSnackbar({
                    message: 'Neues Passwort generiert',
                    color: 'warning',
                    timeout: 1000
                })
            }
        },
        async qrcodeString() {
            const compressedName = await compress(this.userName);
            const compressedPW = await compress(this.qrPassword);

            this.qrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            });
        },
        async parentQrcodeString() {
            const compressedName = await compress(this.parentLoginId);
            const compressedPW = await compress(this.parentPassword);

            this.parentQrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            });
        },
        async insertGroupNames(groups) {
            //TODO: Replace function to not call each group on its own
            for (let i = 0; i < groups.length; i++) {
                this.groups.push(await this.getGroup(groups[i]));
            }
            return this.groups;
        },
        async suggestLoginId(oldProfile) {
            if (!this.editLoginId && !oldProfile) {
                let id =
                    this.name.toLowerCase() + this.lastName.slice(0, 1).toLowerCase();
                let data = {
                    userName: id,
                };
                let available = await this.isLoginIdAvailable(data);

                if (available) {
                    this.userName = id;
                } else {
                    let i = 1;
                    this.userName = id + i;
                    for (let i = 1; !available; i++) {
                        id =
                            this.name.toLowerCase() +
                            this.lastName.slice(0, 1).toLowerCase() +
                            i;
                        data = {
                            userName: id,
                        };
                        available = await this.isLoginIdAvailable(data);
                        this.userName = id;
                    }
                }
            }
            await this.validateInput();
        },
        //checks if password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
            return pwCheck.test(this.password);
        },
        checkParentPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");
            return pwCheck.test(this.parentPassword);
        },
        async checkIdAvailability(loginId) {
            let data = {
                userName: loginId,
            };
            return await this.isLoginIdAvailable(data);
        },

        async requestParents() {
            this.parents = await this.getParents();
        },

        setDeleteDialog(subject) {
            switch (subject) {
                case 'pupil':
                    this.deleteDialogTitle = 'Benutzer löschen?';
                    this.deleteDialogText = 'Sind Sie sicher, dass Sie den Benutzer löschen möchten?';
                    this.deleteDialogSubject = subject;
                    this.deleteDialog = true;
                    break;
                case 'parent':
                    this.deleteDialogTitle = 'Eltern-Account löschen?';
                    this.deleteDialogText = 'Sind Sie sicher, dass Sie den Eltern-Account löschen möchten?';
                    this.deleteDialogSubject = subject;
                    this.deleteDialog = true;
                    break;
                default:

            }
        },

        generateParentLoginId() {
            this.parentLoginId = this.lastName;
        },
    },
};
</script>

<style lang="scss" scoped>
table,
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

tr {
    height: 40px;
}

table tr th:first-child,
table tr td:first-child {
    width: 10px !important;
}

table tr th,
table tr td {
    width: 9em;
    border: none;
}

table,
tr,
td {
    border: none;
}

@media only screen and (max-width: 700px) {
    .babyViewCheckbox {
        margin-left: 40px
    }
}

.blackText {
    color: rgba(0, 0, 0, 0.87) !important;
}

.input {
    display: block;
    width: 100%;
    max-width: 100%;
    border-radius: 5px;
    border-width: 0;
    color: black;
    padding: 1px 5px 1px 5px;
    border: none !important;
    background-color: var(--v-hellgrau-base);
    overflow: hidden;
    appearance: none;
}

.errorInput {
    border: 2px solid red !important;
}

.input[disabled] {
    color: grey;
    background-color: #cacaca;
}

.noDisplay {
    display: none !important;
}

.editContainer {
    min-height: 40vh;
    height: fit-content;
    width: 90%;
    border-radius: 15px;
    box-shadow: 1px 5px 5px silver;
    margin: auto;
    margin-bottom: 5em;
}

.mainControlButtons {
    font-weight: 300;
    font-size: 10px;
    border-radius: 8px;

    & span {
        line-height: 0 !important;
    }
}

.mainFunctionButtons {
    width: 38px !important;
    height: 38px !important;
    border-radius: 8px;

    img {
        width: 27px !important;
        height: 27px !important;
        filter: brightness(1000%);
    }
}

.infoTextToolTip {
    max-width: 250px;
    background-color: var(--v-blau-base);
}

.editPupilButton {
    border-radius: 5px;
    text-transform: inherit;

    img {
        filter: brightness(10%);
        width: 15px;
        margin-right: 5px;
        margin-top: -2px;
    }
}

.generatePasswordButton {
    max-height: 25px;
    max-width: 100%;
    width: 100%;
    text-transform: inherit;
    border-radius: 8px;

    img {
        /*filter: brightness(10%);*/
        width: 15px;
        margin-right: 5px;
        margin-top: -2px;
    }
}

input[type="date"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.6rem;
}
</style>
