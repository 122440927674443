import * as backend from '../../api/backend';
import {checkResponseStatus} from '@/util/check';

const actions = {
  async getParents () {
    try {
      const res = await backend.getParents();
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async getParent ({ commit, dispatch }, params) {
    try {
      const res = await backend.getParent(params);
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

    // returns pupilParent for requesting parent
  async getMeParent () {
    try {
      const res = await backend.getMeParent();
      await checkResponseStatus(200, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async createParent ({ commit, dispatch }, params) {
    try {
      const res = await backend.postParent(params);
      await checkResponseStatus(201, res);
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async editParent ({ commit, dispatch }, params) {
    try {
      const id = params._id
      delete params._id;
      const res = await backend.patchParent(id, params);
      if (res.status === 409) {
        return 409;
      }
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async deleteParent ({commit, dispatch}, id) {
    try {
      const res = await backend.deleteParent(id);
      if (res.status === 204) {
        return 204;
      }
      return await res.json();
    }
    catch(err) {
      return err.response.status;
    }
  },

  async changePasswordParent({ commit, dispatch }, params) {
    try {
        let id = params.userroleId;
        delete params.userroleId;
        return await backend.patchParent(id, params);
    }
    catch (err) {
        return err.response;
    }
  },

    async getChildTeacher({ commit, dispatch }, childId) {
        try {
            const res = await backend.getChildTeacher(childId)
            await checkResponseStatus(200, res);
            return await res.json();
        }
        catch (err) {
            return err.response;
        }
    },

  async changeEmailParent({ commit, dispatch }, params) {
    try {
      let id = params.userroleId;
      delete params.userroleId;
      return await backend.patchParent(id, params);
    }
    catch (err) {
      return err.response;
    }
  }
}

export default {
  namespaced: true,
  actions,
};
