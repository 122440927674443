<template>
    <div>
        <div class="greyBox d-flex flex-column align-center">
            <div
                style="max-width: 500px"
                class="ma-10"
            >
                <!-- <h1
                     aria-label="Profil bearbeiten"
                     class="header"
                     tabindex="0"
                     style="width: fit-content"
                 >
                     Profil bearbeiten
                 </h1> -->
                <div v-if="account.role !== 'pupil'">
                    <p class="font-weight-bold mb-1">
                        Benachrichtigungen
                    </p>
                    <p class="mb-4">
                        Sie können ihre E-Mail Adresse angeben um Benachrichtigungen von eKlara per E-Mail zu erhalten.
                    </p>
                    <p>E-Mail</p>
                    <v-text-field
                        v-model="email"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 500px;"
                    />
                    <div
                        style="display: flex; flex-direction: column"
                        class="mb-6"
                    >
                        <div style="display: flex; flex-direction: row; justify-content: space-between">
                            <p>Schwarzes Brett</p>
                            <v-checkbox
                                v-model="notificationBlackboard"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                        <div
                            v-if="account.role === 'parent'"
                            style="display: flex; flex-direction: row; justify-content: space-between"
                        >
                            <p>Elternbriefe</p>
                            <v-checkbox
                                v-model="notificationParentalLetter"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                        <div
                            v-if="account.role === 'teacher'"
                            style="display: flex; flex-direction: row; justify-content: space-between"
                        >
                            <p>Entschuldigungen</p>
                            <v-checkbox
                                v-model="notificationSicknote"
                                hide-details
                                color="black"
                                style="margin-top: 0; padding-top: 0"
                            />
                        </div>
                    </div>
                </div>
                <p class="font-weight-bold mb-1">
                    Passwort ändern
                </p>

                <p class="mb-4">
                    Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Buchstaben und eine Zahl enthalten.
                </p>
                <p>Altes Passwort</p>
                <v-text-field
                    v-model="oldPassword"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <!-- <p v-if="!userrole.securityQuestion">
                    Sicherheitsfrage auswählen
                </p>
                <p v-else>
                    Sicherheitsfrage
                </p> -->
                <!-- TODO: remember to use return-object if needed -->
                <!-- <v-select
                    v-model="securityQuestion"
                    aria-label="Sicherheitsfrage"
                    outlined
                    dense
                    hide-details
                    :items="securityQuestions"
                    :menu-props="{ bottom: true, offsetY: true }"
                    no-data-text="Sicherheitsfragen konnten nicht geladen werden"
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <div v-if="securityQuestion">
                    <p v-if="userrole.securityQuestion">
                        Sicherheitsfrage beantworten
                    </p>
                    <p v-else>
                        Neue Sicherheitsfrage setzen
                    </p>
                    <v-text-field
                        v-model="securityQuestionAnswer"
                        outlined
                        dense
                        hide-details
                        class="mb-2"
                        style="max-width: 600px;"
                    />
                </div> -->

                <p>Neues Passwort</p>
                <v-text-field
                    v-model="newPassword"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <p>Neues Passwort wiederholen</p>
                <v-text-field
                    v-model="newPasswordRepeat"
                    :type="showPassword ? 'text' : 'password'"
                    outlined
                    dense
                    hide-details
                    class="mb-2"
                    style="max-width: 500px;"
                />

                <v-row class="d-flex justify-center pa-0 ma-0">
                    <v-checkbox
                        v-model="showPassword"
                        aria-label="Passwort anzeigen"
                        hide-details
                        class="ma-0 pa-0 pl-1"
                    />
                    <p aria-hidden="true">
                        Passwort anzeigen
                    </p>
                </v-row>

                <!-- Simple Editor Stuff -->
                <div
                    v-if="accountRole === 'teacher'"
                >
                    <p class="font-weight-bold mb-1">
                        Editor-Modus
                    </p>
                    <v-checkbox
                        v-model="simpleEditorValue"
                        inset
                        :label="`Einfacher Editor-Modus für Lehrer`"
                    />
                </div>

                <v-row
                    id="btnContainer"
                    class="d-flex justify-space-between pa-0 ma-0 mt-5"
                >
                    <v-btn
                        class="backButton text-none"
                        elevation="0"
                        @click="toggleProfileManagement"
                    >
                        &lt; Zurück zu eKlara
                    </v-btn>

                    <v-btn
                        id="custom-disabled"
                        :disabled="!checkPw && securityQuestionAnswer.length > 0"
                        color="gruen"
                        class="changePwBtn text-none"
                        elevation="0"
                        dark
                        @click="saveProfile"
                    >
                        Speichern
                    </v-btn>
                </v-row>
            </div>
        </div>

        <qrcode-vue
            v-show="false"
            ref="qrcodecontainer"
            :value="qrCodeData"
            size="200"
        />

        <v-dialog
            v-model="downloadPdfDialog"
            width="600px"
        >
            <v-card>
                <v-card-title>Passwort herunterladen</v-card-title>
                <v-card-text class="d-flex flex-column align-center">
                    <p
                        class="mb-4"
                    >
                        Das Passwort wurde erfolgreich geändert. Die neuen QR-Codes können über den folgenden Knopf heruntergeladen werden.
                    </p>
                    <v-btn
                        class="text-none mt-2"
                        elevation="0"
                        style="width: 150px"
                        @click="account.role !== 'parent' ? pdf() : parentPdf()"
                    >
                        QR Download
                    </v-btn>
                </v-card-text>
                <v-card-actions class="d-flex justify-end px-6 pb-4">
                    <v-btn
                        color="gruen"
                        class="text-none mt-2"
                        elevation="0"
                        dark
                        @click="handleFinish()"
                    >
                        Fertig
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import { compress } from "shrink-string";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import QrcodeVue from "qrcode.vue";
import roboto from "@/assets/Fonts/Roboto-Regular.ttf";
import robotoBold from "@/assets/Fonts/Roboto-Bold.ttf";

import pdfFile from "@/assets/PDF/Schueler-QR-Vorlage.pdf";
import pdfParentFile from "@/assets/PDF/Eltern-QR-Vorlage.pdf";
// TODO we need a pdfTeacherFile

export default {
    name: "ProfileManagement",
    components: {
        QrcodeVue,
    },
    data() {
        return {
            pdfFile,
            pdfParentFile,
            roboto,
            robotoBold,

            securityQuestions: [],          // holds the available security questions
            securityQuestion: null,         // is a security question selected in the dialog
            securityQuestionAnswer: '',     // the answer to the selected security question

            email: '',
            notificationBlackboard: false,
            notificationParentalLetter: false,
            notificationSicknote: false,

            showPassword: false,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',

            qrPassword: '',
            qrCodeData: "{ name: '', pw: '' }",
            name: '',
            lastName: '',

            downloadPdfDialog: false,

            account: {},
            userrole: {},

            // Simple Editor Stuff
            simpleEditorValue: false,
        }
    },
    computed: {
        ...mapState("profileManagement", ["profileManagement"]),
        ...mapGetters('auth', [ 'accountRole' ]),

        // checks if if new PWs are equal & password is at least of length 8 and contains one letter and one number
        checkPw() {
            const pwCheck = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})");

            if (this.newPassword.trim() === this.newPasswordRepeat.trim()) {
                return pwCheck.test(this.newPassword);
            }
            else {
                return false;
            }
        },
    },
    async mounted() {
        this.securityQuestions = await this.getSecurityQuestions();

        this.account = await this.getCurrentAccount();
        switch (this.account.role) {
            case 'pupil':
                this.userrole = await this.getMePupil();
                break;
            case 'teacher':
                this.userrole = await this.getMeTeacher();
                break;
            case 'parent':
                this.userrole = await this.getMeParent();
                break;
            default:
                console.log('no userrole identified');
                break;
        }
        if(this.userrole) {
            if(this.userrole.email) {
                this.email = this.userrole.email;
            }
            if(this.userrole.notifications) {
                this.notificationBlackboard = this.userrole.notifications.notificationBlackboard;
                this.notificationParentalLetter = this.userrole.notifications.notificationParentalLetter;
                this.notificationSicknote = this.userrole.notifications.notificationSicknote;
            }
        }


        // if securityQuestion has been set by user, remove all other choices
        if (this.userrole.securityQuestion) {
            this.securityQuestion = this.userrole.securityQuestion;
            this.securityQuestions = this.userrole.securityQuestion;
        }

        if (this.userrole) {
            this.simpleEditorValue = this.userrole.simpleEditor || false;
        }
    },
    methods: {
        ...mapActions('profileManagement', ['toggleProfileManagement']),
        ...mapActions('pupils', ['getMePupil', 'changePasswordPupil']),
        ...mapActions('teachers', ['getMeTeacher', 'changePasswordTeacher', 'editTeacherProfile']),
        ...mapActions('parent', ['getMeParent', 'changePasswordParent']),
        ...mapActions("auth", ["getCurrentAccount", "logoutUser", "reloadDontLogout", 'getSecurityQuestions']),
        ...mapMutations('snackbar', ['showSnackbar']),


        async saveProfile() {
            this.name = this.userrole.name;
            this.lastName = this.userrole.lastName;

            // create a QR PW, it's different from regular one for security reasons
            // it's saved in the QR code as base64 and if user uses same PW on multiple websites this helps a bit
            let qrResult = ''
            let allCharacters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 16; i++) {
                qrResult += allCharacters.charAt(
                    Math.floor(Math.random() * allCharacters.length)
                );
            }
            this.qrPassword = qrResult;
            await this.qrcodeString();

            let body = {
                userroleId: this.userrole._id,
                ...(this.oldPassword !== '' ? { 'oldPassword' : this.oldPassword } : {}),
                ...(this.newPassword !== '' ? { 'password' : this.newPassword } : {}),
                // ...(this.qrPassword !== this.userrole. ? { 'qrPassword' : this.qrPassword } : {}),
                email: this.email,                
                // securityQuestion: this.securityQuestion,
                // securityAnswer: this.securityQuestionAnswer,
                simpleEditor: this.simpleEditorValue, 
                notifications: {
                    notificationBlackboard: this.notificationBlackboard,
                    notificationParentalLetter: this.notificationParentalLetter,
                    notificationSicknote: this.notificationSicknote,
                }
            }
            let res;
            switch (this.account.role) {
                case 'pupil':
                    res = await this.changePasswordPupil(body);
                    break;
                case 'teacher':
                    res = await this.changePasswordTeacher(body);
                    break;
                case 'parent':
                    res = await this.changePasswordParent(body);
                    break;
            }
            if (res.status === 200) {
                this.showSnackbar({ message: "Änderungen erfolgreich geändert.", color: "success" });
                // if(await response.reason === 'changedEmail') {
                //     this.showSnackbar({ message: "E-Mail erfolgreich geändert.", color: "success" });
                //     this.clearPasswordForm();
                // } else if(await response.reason === 'changedPassword') {
                //     this.showSnackbar({ message: "Passwort erfolgreich geändert.", color: "success" });
                //     this.downloadPdfDialog = true;
                //     this.clearPasswordForm();
                // }
            }
            else {
                this.showSnackbar({ message: "Etwas hat nicht funktioniert.", color: "error" });
            }
        },

        clearPasswordForm() {
            this.password = this.newPassword;
            this.oldPassword = '';
            this.newPassword = '';
            this.newPasswordRepeat = '';
            this.securityQuestion = null;
            this.securityQuestionAnswer = '';
            this.email = '';
        },

        handleFinish() {
            this.downloadPdfDialog = false;
            this.password = '';

            // logout user, because PW was changed
            this.logoutUser();
            this.$router.push({ name: 'login' });
        },

        //#region pdf methods
        // generate QR password, blatantly copied from Schueler.vue
        async qrcodeString() {
            const compressedName = await compress(this.account.accountName);
            const compressedPW = await compress(this.qrPassword);

            this.qrCodeData = JSON.stringify({
                name: compressedName,
                pw: compressedPW,
            });
        },

        async pdf() {
            const existingPdfBytes = await fetch(this.pdfFile).then((res) =>
                res.arrayBuffer()
            );

            const qrCode = this.$refs.qrcodecontainer.$refs["qrcode-vue"].toDataURL(
                "image/png"
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const pngImage = await pdfDoc.embedPng(qrCode);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];

            // Draw a string of text diagonally across the first page
            firstPage.drawText(this.name + " " + this.lastName, {
                x: 100.7,
                y: 670,
                size: 11,
                font: robotoFont,
            });

            firstPage.drawText(this.name + ",", {
                x: 168,
                y: 536,
                size: 11,
                font: robotoFont,
            });

            firstPage.drawText(this.account.accountName, {
                x: 100.7,
                y: 412,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(this.password, {
                x: 100.7,
                y: 368,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 100.7,
                y: 325,
                size: 11,
                font: robotoBoldFont,
            });

            //QR1
            firstPage.drawImage(pngImage, {
                x: 47,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 200,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.account.accountName, {
                x: 200,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 200,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 200,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 42,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 102,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            //QR2
            firstPage.drawImage(pngImage, {
                x: 330,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 482,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.account.accountName, {
                x: 482,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 482,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 482,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 325,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 385,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray(this.account.accountName + ".pdf", pdfBytes);
        },

        async parentPdf() {
            const existingPdfBytes = await fetch(this.pdfParentFile).then((res) =>
                res.arrayBuffer()
            );

            // Load a PDFDocument from the existing PDF bytes
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            pdfDoc.registerFontkit(fontkit);

            const qrCode = this.$refs.qrcodecontainer.$refs[
                "qrcode-vue"
            ].toDataURL("image/png");
            const pngImage = await pdfDoc.embedPng(qrCode);

            const robotoBytes = await fetch(this.roboto).then((res) =>
                res.arrayBuffer()
            );
            const robotoBoldBytes = await fetch(this.robotoBold).then((res) =>
                res.arrayBuffer()
            );

            const robotoFont = await pdfDoc.embedFont(robotoBytes);
            const robotoBoldFont = await pdfDoc.embedFont(robotoBoldBytes);

            // Get the first page of the document
            const pages = pdfDoc.getPages();
            const firstPage = pages[0];
            const form = pdfDoc.getForm();
            const button = form.createButton("some.button.field");

            // Get the width and height of the first page
            const { width, height } = firstPage.getSize();

            firstPage.drawText(this.account.accountName, {
                x: 100.7,
                y: 403,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(this.password, {
                x: 100.7,
                y: 358,
                size: 11,
                font: robotoBoldFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 100.7,
                y: 314,
                size: 11,
                font: robotoBoldFont,
            });

            //QR1
            firstPage.drawImage(pngImage, {
                x: 47,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 200,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.account.accountName, {
                x: 200,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 200,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 200,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 42,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 102,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            //QR2
            firstPage.drawImage(pngImage, {
                x: 330,
                y: 56,
                width: 70,
                height: 70,
            });

            firstPage.drawText("Nutzername", {
                x: 482,
                y: 125,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.account.accountName, {
                x: 482,
                y: 110,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Passwort", {
                x: 482,
                y: 90,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(this.password, {
                x: 482,
                y: 75,
                size: 10,
                font: robotoBoldFont,
            });

            firstPage.drawText("Internetseite", {
                x: 325,
                y: 33,
                size: 10,
                font: robotoFont,
            });

            firstPage.drawText(window.location.hostname, {
                x: 385,
                y: 33,
                size: 10,
                font: robotoBoldFont,
            });

            // Serialize the PDFDocument to bytes (a Uint8Array)
            const pdfBytes = await pdfDoc.save();

            this.saveByteArray(this.account.accountName + ".pdf", pdfBytes);
        },

        saveByteArray(reportName, byte) {
            let blob = new Blob([byte], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = reportName;
            link.click();
        },
        //#endregion

        //#region Simple Editor
        async updateTeacher(event) {
            const requestBody = {
                ...this.userrole,
                simpleEditor: this.simpleEditorValue
            };

            const response = await this.editTeacherProfile(requestBody);
            if (response.status === 200) {
                const responseJson = await response.json();
                this.simpleEditorValue = responseJson.simpleEditor;
                await this.showSnackbar({
                    message: 'Einstellungen erfolgreich aktualisiert!'
                });
            } else {
                await this.showSnackbar({
                    message: 'Änderung konnte nicht gespeichert werden.',
                    color: 'warning'
                });
            }
        }
        //#endregion
    },
}
</script>

<style lang="scss" scoped>
::v-deep .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
}

#custom-disabled.v-btn--disabled {
    background-color: var(--v-fgrau-base) !important;
    color: white;
}

.header {
    margin-bottom: 0.5em;
}

.greyBox {
    height: 100vh;
    background-color: #ffffff;
    border-radius: 8px;
}

.changePwBtn {
    border-radius: 10px;
}

.backButton {
    color: var(--v-dunkelgrau-base);
    border-radius: 10px !important;
}

@media only screen and (max-width: 500px) {
    #btnContainer {
        flex-direction: column;
    }

    .changePwBtn {
        order: 1;
    }

    .backButton {
        margin-top: 20px;
        order: 2;
    }
}
</style>
